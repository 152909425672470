import React from 'react'
import config from 'mulberry/config'

const Logo = ({ src, ...rest }) => (
  <a href={config.static} {...rest}>
    <img src={src} width="170" alt="" />
  </a>
)

export default Logo
