const dev = {
  api: 'http://localhost:7100',
  integrationsApi: 'http://localhost:7600',
  dashboard: 'http://localhost:3000/',
  admin: 'http://localhost:7100/admin/',
  static: 'http://localhost:3100',
  logoPath: 'https://mulberry-partner-logos-develop',
  signup: 'http://localhost:3200',
  explore: 'http://localhost:3400',
  launchDarklyClientID: process.env.LD_CLIENT_SIDE_ID,
  d2cExtensionID: 'nmdbegllkjaigoljpoegbpjjlmpfhlge',
}

const staging = {
  api: 'https://partner-staging.getmulberry.com',
  integrationsApi: 'https://mb-shim-staging.getmulberry.com/',
  dashboard: 'https://dashboard-staging.getmulberry.com',
  admin: 'https://partner-staging.getmulberry.com/admin/',
  static: 'https://staging.getmulberry.com',
  logoPath: 'https://mulberry-partner-logos-staging',
  signup: 'https://signup-staging.getmulberry.com',
  explore: 'https://explore-staging.getmulberry.com',
  launchDarklyClientID: '5ef0d4b7572ea10ab14d7307',
  d2cExtensionID: 'doheogeolmnlejjfpdfldnnicofckndc'
}

const production = {
  api: 'https://partner.getmulberry.com',
  integrationsApi: 'https://mb-shim.getmulberry.com/',
  dashboard: 'https://dashboard.getmulberry.com',
  admin: 'https://partner.getmulberry.com/admin/',
  static: 'https://www.getmulberry.com',
  logoPath: 'https://mulberry-partner-logos',
  signup: 'https://signup.getmulberry.com',
  explore: 'https://explore.getmulberry.com',
  launchDarklyClientID: '5ef0d4b7572ea10ab14d7308',
  ga_key_d2c: 'UA-190355955-4',
  ga_key_dashboard: 'UA-190355955-7',
  d2cExtensionID: 'doheogeolmnlejjfpdfldnnicofckndc',
}

const develop = {
  api: 'https://partner-develop.getmulberry.com',
  integrationsApi: 'https://mb-shim-develop.getmulberry.com/',
  dashboard: 'https://dashboard-develop.getmulberry.com',
  admin: 'https://partner-develop.getmulberry.com/admin/',
  static: 'https://develop.getmulberry.com',
  logoPath: 'https://mulberry-partner-logos-develop',
  signup: 'https://signup-develop.getmulberry.com',
  explore: 'https://explore-develop.getmulberry.com',
  launchDarklyClientID: '5f187523b2489c0b41275dc1',
  d2cExtensionID: 'doheogeolmnlejjfpdfldnnicofckndc',
}

const testing = {
  api: 'https://partner-testing.getmulberry.com',
  dashboard: 'https://dashboard-testing.getmulberry.com',
  admin: 'https://partner-testing.getmulberry.com/admin/',
  static: 'https://testing.getmulberry.com',
  logoPath: 'https://mulberry-partner-logos-testing',
  signup: 'https://signup-testing.getmulberry.com',
  explore: 'https://explore-develop.getmulberry.com',
  launchDarklyClientID: '5f187523b2489c0b41275dc1',
  d2cExtensionID: 'doheogeolmnlejjfpdfldnnicofckndc',
}

const k8spoc = {
  api: 'https://partner-k8s-poc.getmulberry.com',
  integrationsApi: 'https://mb-shim-k8s-poc.getmulberry.com/',
  dashboard: 'https://dashboard-poc.getmulberry.com',
  admin: 'https://partner-k8s-poc.getmulberry.com/admin/',
  static: 'https://k8s-poc.getmulberry.com',
  logoPath: 'https://mulberry-partner-logos-testing',
  signup: 'https://signup-poc.getmulberry.com',
  explore: 'https://explore-poc.getmulberry.com',
  launchDarklyClientID: '5f4930af4bc08c08e257dc81',
  d2cExtensionID: 'doheogeolmnlejjfpdfldnnicofckndc',
}

const testing2 = {
  api: 'https://partner-testing2.getmulberry.com',
  dashboard: 'https://dashboard-testing2.getmulberry.com',
  admin: 'https://partner-testing2.getmulberry.com/admin/',
  static: 'https://testing2.getmulberry.com',
  logoPath: 'https://mulberry-partner-logos-testing2',
  signup: 'https://signup-testing2.getmulberry.com',
  explore: 'https://explore-develop.getmulberry.com',
  launchDarklyClientID: '5f187523b2489c0b41275dc1',
  d2cExtensionID: 'doheogeolmnlejjfpdfldnnicofckndc',
}

const testing3 = {
  api: 'https://partner-testing3.getmulberry.com',
  dashboard: 'https://dashboard-testing3.getmulberry.com',
  admin: 'https://partner-testing3.getmulberry.com/admin/',
  static: 'https://testing3.getmulberry.com',
  logoPath: 'https://mulberry-partner-logos-testing3',
  signup: 'https://signup-testing3.getmulberry.com',
  explore: 'https://explore-develop.getmulberry.com',
  launchDarklyClientID: '5f187523b2489c0b41275dc1',
  d2cExtensionID: 'doheogeolmnlejjfpdfldnnicofckndc',
}

let config

if (
  process.env.REACT_APP_STAGE === 'staging' ||
  process.env.REACT_APP_STAGE === 'master'
) {
  // using CI branch for REACT_APP_STAGE which is "master" for staging
  config = staging
} else if (process.env.REACT_APP_STAGE === 'testing') {
  config = testing
} else if (process.env.REACT_APP_STAGE === 'testing2') {
  config = testing2
} else if (process.env.REACT_APP_STAGE === 'testing3') {
  config = testing3
} else if (process.env.REACT_APP_STAGE === 'production') {
  config = production
} else if (process.env.REACT_APP_STAGE === 'poc') {
  config = k8spoc
} else if (process.env.REACT_APP_STAGE) {
  config = develop
} else {
  config = dev
}

export default {
  ...config,
  stripeToken: process.env.STRIPE_PUB_KEY || '',
  mixpanelToken: process.env.MIXPANEL_PUB_KEY,
  squareLocationID: process.env.SQUARE_LOCATION_ID,
  squareApplicationID: process.env.SQUARE_APPLICATION_ID,
  googleOAuthInternalClientID: process.env.GOOGLE_OAUTH_INTERNAL_CLIENT_ID,
}
