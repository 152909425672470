import React from 'react';
import styled from 'styled-components';

const Placeholder = styled.div`
  height: 100vw;
`;

export const asyncComponent = getComponent => {
  class AsyncComponent extends React.Component {
    static Component = null;
    state = { Component: this.Component };
    UNSAFE_componentWillMount() {
      if (!this.state.Component) {
        getComponent().then(({ default: Component }) => {
          this.Component = Component;
          this.setState({ Component });
        });
      }
    }
    render() {
      const { Component } = this.state;
      if (Component) {
        return <Component {...this.props} />;
      }
      return <Placeholder />;
    }
  };
  return <AsyncComponent />
};
