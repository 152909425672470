import { TOKEN_SUBMIT, TOKEN_RESPONSE, REGISTER_SUBMIT, REGISTER_RESPONSE } from './actions';

let initialState = {
  isLoading: true,
  isComplete: false,
  hasEmail: false
}

export default function register(state = initialState, action) {
    switch (action.type) {
        case TOKEN_SUBMIT:
            return action;
        case TOKEN_RESPONSE:
            return Object.assign({}, state, {
                isLoading: false,
                hasEmail: action.token.has_email,
                expiredToken: action.token.expired_token === true,
                registeredUser: action.token.registered === true
            });
        case REGISTER_SUBMIT:
            return Object.assign({}, state, {
                isLoading: true
            });
        case REGISTER_RESPONSE:
          return Object.assign({}, state, {
                isLoading: false,
                isComplete: true
            });
        default:
            return state;
    }
}