import React from 'react';
import { Outlet } from 'react-router-dom';

import styles from "../index.module.css";

export const LayoutEmpty = () => {
  return (
    <div className={styles.layoutEmpty}>
      <Outlet />
    </div>
  )
}
