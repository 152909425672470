import React from 'react';
import styled from 'styled-components';
import axios from "axios";
import config from "mulberry/config";
import breakpoint from 'styled-components-breakpoint';
import ThemeProvider from 'mulberry/ThemeProvider/ThemeProvider';

// makes component full sized but still keep the AppTemplate layout
export const FullWidthContainerWrapper = styled.div`
  margin: 0 -135px;
  ${breakpoint(0, 'desktop')`
    margin: 0 -15px;
  `}
  > div {
    padding-left: 135px;
    padding-right: 135px;
    ${breakpoint(0, 'desktop')`
      padding-left: 15px;
      padding-right: 15px;
    `}
    ${breakpoint('maxContent')`
      margin: 0 calc((100vw - 1440px)/(-2));
      padding-left: calc((100vw - 1440px)/2 + 135px);
      padding-right: calc((100vw - 1440px)/2 + 135px);
    `}
  }
`;

export const FullWidthContainer = ({ children }) => (
  <ThemeProvider>
    <FullWidthContainerWrapper>
      { children }
    </FullWidthContainerWrapper>
  </ThemeProvider>
);

export class TimerInterval {
    constructor(handler, timeOut) {
        this.handler = handler;
        this.timeOut = timeOut;
    }
    start() {
        if (!this.timerObj) {
            this.stop();
            this.timerObj = setInterval(this.handler, this.timeOut);
        }
        return this;
    }
    stop() {
        if (this.timerObj) {
            clearInterval(this.timerObj);
            this.timerObj = null;
        }
        return this;
    }
    // start with new interval, stop current interval
    restart() {
        return this.stop().start();
    }
}

export const getParameterByName = (name, url = window.location.search) => {
  const normalized = name.replace(/[[\]]/g, '\\$&') // Remove slashes

  // Find where string is present
  const regex = new RegExp(`[?&]${normalized}(=([^&#]*)|&|#|$)`)
  const results = regex.exec(url)

  if (!results) return null
  if (!results[2]) return ''

  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const nonceSubmit = (nonce, path, email = '', password = '') => {
  const payload = { email, password, nonce };
  axios.defaults.withCredentials = true;

  axios.post(`${config.api}/api/auth/login`, payload)
    .then(() => window.location = path)
    .catch(() => {
      window.location = `${config.explore}/login`;
    })
}

export const configureZendeskScript = () => {
  // Start of mulberrytechnology Zendesk Widget script
  const script = document.createElement('script')
  script.src =
    'https://static.zdassets.com/ekr/snippet.js?key=b22ce06c-23cc-493b-8d7c-d8bd47b43f28'
  script.id = 'ze-snippet'

  return document.getElementsByTagName('head')[0].appendChild(script)
}

export const configureIntercomScript = () => {
  const APP_ID = 'y314zvyt'

  window.intercomSettings = {
    app_id: APP_ID
  };

  const script = document.createElement('script')
  script.innerHTML = (function () { var w = window; var ic = w.Intercom; if (typeof ic === "function") { ic('reattach_activator'); ic('update', w.intercomSettings); } else { var d = document; var i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; var l = function () { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://widget.intercom.io/widget/' + APP_ID; var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }; if (document.readyState === 'complete') { l(); } else if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } } })()
}
