import heroImageDesktop from "mulberry/assets/images/illu-hero-large-desktop.png";
import heroImageTablet from "mulberry/assets/images/illu-hero-tablet.png";
import heroImageMobile from "mulberry/assets/images/illu-hero-mobile.png";

import unlockRevenueDesktop from "mulberry/assets/images/unlockrevenue-hero-illu.png";
import unlockRevenueTablet from "mulberry/assets/images/unlockrevenue-hero-illu-t.png";
import unlockRevenueMobile from "mulberry/assets/images/unlock-revenue-hero-m.png";

import boostCustomerDesktop from "mulberry/assets/images/boost-customer-hero-illu.png";
import boostCustomerTablet from "mulberry/assets/images/boost-customer-hero-illu-t.png";
import boostCustomerMobile from "mulberry/assets/images/boost-customer-hero-illu-m.png";

import holisticCustomerDesktop from "mulberry/assets/images/holistic-hero-illu.png";
import holisticCustomerTablet from "mulberry/assets/images/holistic-hero-illu-t.png";
import holisticCustomerMobile from "mulberry/assets/images/holistic-hro-illu-m.png";

import fasterAndSmarterDesktop from "mulberry/assets/images/fasterAndSmarter/faster-smarter-ill-d.png";
import fasterAndSmarterTablet from "mulberry/assets/images/fasterAndSmarter/faster-smarter-illu-t.png";
import fasterAndSmarterMobile from "mulberry/assets/images/fasterAndSmarter/faster-smarter-illu-m.png";

import customerDesktop from "mulberry/assets/images/customer/customer-header-illu.png";
import customerTablet from "mulberry/assets/images/customer/customer-header-illu-t.png";
import customerMobile from "mulberry/assets/images/customer/customer-header-m.png";

import contactUsImgDesktop from "mulberry/assets/images/contactUs/illu-contact-us-d.png";
import contactUsImgTablet from "mulberry/assets/images/contactUs/illu-contact-us-t.png";
import contactUsImgMobile from "mulberry/assets/images/contactUs/illu-contact-us-m.png";

import partnerWithUsImgDesktop from "mulberry/assets/images/partnerWithUs/illu-partner-with-us-d.png";
import partnerWithUsImgTablet from "mulberry/assets/images/partnerWithUs/illu-partner-with-us-t.png";
import partnerWithUsImgMobile from "mulberry/assets/images/partnerWithUs/illu-partner-with-us-m.png";

import contactUsBgDesktop from "mulberry/assets/images/blobs/contant-us-bg-large.svg";
import contactUsBgTablet from "mulberry/assets/images/contactUs/orange-t.svg";
import contactUsBgMobile from "mulberry/assets/images/contactUs/orange-m.svg";

import blobGroupBgDesktopLarge from "mulberry/assets/images/blobs/right-group.svg";
import teamVioletBgDesktop from "mulberry/assets/images/ourTeam/bg/violet-d.svg";
import teamVioletBgTablet from "mulberry/assets/images/ourTeam/bg/violet-t.svg";
import teamVioletBgMobile from "mulberry/assets/images/ourTeam/bg/violet-m.svg";

import boostCustomerDesktopLargeBg from "mulberry/assets/images/blobs/boost-customer-hero-bg.svg";
import boostCustomerDesktopBg from "mulberry/assets/images/boost-customer-hero-bg-d.png";
import boostCustomerTabletBg from "mulberry/assets/images/boost-customer-hero-bg-t.png";
import boostCustomerMobileBg from "mulberry/assets/images/boost-customer-hero-bg-m.png";

import customerBgDesktop from "mulberry/assets/images/customer/customer-header-bg.png";
import customerBgDesktopLarge from "mulberry/assets/images/blobs/customer-header-bg.svg";
import customerBgTablet from "mulberry/assets/images/customer/customer-header-bg-t.png";
import customerBgMobile from "mulberry/assets/images/customer/customer-header-bg-m.png";

import fasterAndSmarterDesktopBgLarge from "mulberry/assets/images/blobs/faster-smarter-illu-bg-d.svg";
import fasterAndSmarterDesktopBg from "mulberry/assets/images/fasterAndSmarter/faster-smarter-illu-bg-d.png";
import fasterAndSmarterTabletBg from "mulberry/assets/images/fasterAndSmarter/faster-smarter-illu-bg-t.png";
import fasterAndSmarterMobileBg from "mulberry/assets/images/fasterAndSmarter/faster-smarter-illu-bg-m.png";

import holisticDesktopBg from "mulberry/assets/images/holistic-illu-background-d.png";
import holisticDesktopLargeBg from "mulberry/assets/images/blobs/holistic-hero-background.svg";
import holisticTabletBg from "mulberry/assets/images/holistic-illu-background-t.png";
import holisticMobileBg from "mulberry/assets/images/holistic-illu-background-m.png";

import partnerWithUsBgDesktopLarge from "mulberry/assets/images/blobs/partner-with-us-header-bg.png";
import partnerWithUsBgDesktop from "mulberry/assets/images/partnerWithUs/partner-with-us-header-bg-d.svg";
import partnerWithUsBgTablet from "mulberry/assets/images/partnerWithUs/partner-with-us-header-bg-t.svg";
import partnerWithUsBgMobile from "mulberry/assets/images/partnerWithUs/partner-with-us-header-bg-m.svg";

import teamOrangeBgDesktopLarge from "mulberry/assets/images/blobs/contant-us-bg-large.svg";
import teamOrangeBgDesktop from "mulberry/assets/images/ourTeam/bg/orange-d.svg";
import teamOrangeBgTablet from "mulberry/assets/images/ourTeam/bg/orange-t.svg";
import teamOrangeBgMobile from "mulberry/assets/images/ourTeam/bg/orange-m.svg";

import unlockRevenueDesktopBg from "mulberry/assets/images/unlock-revenue-hero-background.png";
import unlockRevenueDesktopLargeBg from "mulberry/assets/images/blobs/unlock-revenue-hero-background.svg";
import unlockRevenueTabletBg from "mulberry/assets/images/unlock-revenue-hero-background-t.png";
import unlockRevenueMobileBg from "mulberry/assets/images/unlock-revenue-hero-bg.png";

import { theme } from "mulberry/ThemeProvider/ThemeProvider";

const blobs = {
  mobile: [
    unlockRevenueMobileBg,
    teamOrangeBgMobile,
    contactUsBgMobile,
    teamVioletBgMobile,
    boostCustomerMobileBg,
    customerBgMobile,
    fasterAndSmarterMobileBg,
    holisticMobileBg,
    partnerWithUsBgMobile,
  ],
  tablet: [
    unlockRevenueTabletBg,
    teamOrangeBgTablet,
    contactUsBgTablet,
    teamVioletBgTablet,
    boostCustomerTabletBg,
    customerBgTablet,
    fasterAndSmarterTabletBg,
    holisticTabletBg,
    partnerWithUsBgTablet,
  ],
  desktop: [
    boostCustomerTabletBg,
    unlockRevenueDesktopBg,
    teamOrangeBgDesktop,
    teamOrangeBgDesktopLarge,
    partnerWithUsBgDesktop,
    holisticDesktopBg,
    fasterAndSmarterDesktopBg,
    customerBgDesktopLarge,
    blobGroupBgDesktopLarge,
    contactUsBgDesktop,
    blobGroupBgDesktopLarge,
    customerBgDesktop,
    boostCustomerDesktopLargeBg,
    fasterAndSmarterDesktopBgLarge,
    holisticDesktopLargeBg,
    partnerWithUsBgDesktopLarge,
    unlockRevenueDesktopLargeBg,
    teamVioletBgDesktop,
    boostCustomerDesktopBg,
  ],
};

const images = {
  mobile: [
    partnerWithUsImgMobile,
    contactUsImgMobile,
    customerMobile,
    fasterAndSmarterMobile,
    holisticCustomerMobile,
    boostCustomerMobile,
    unlockRevenueMobile,
    heroImageMobile,
  ],
  tablet: [
    partnerWithUsImgTablet,
    contactUsImgTablet,
    customerTablet,
    fasterAndSmarterTablet,
    holisticCustomerTablet,
    boostCustomerTablet,
    unlockRevenueTablet,
    heroImageTablet,
  ],
  desktop: [
    partnerWithUsImgDesktop,
    contactUsImgDesktop,
    customerDesktop,
    fasterAndSmarterDesktop,
    holisticCustomerDesktop,
    boostCustomerDesktop,
    unlockRevenueDesktop,
    heroImageDesktop,
  ],
};
export const getResponsiveAssets = () => {
  const { breakpoints } = theme;
  const width = window.innerWidth;
  switch (true) {
    case width < breakpoints.tablet:
      return [...images["mobile"], ...blobs["mobile"]];
    case width > breakpoints.tablet && width < breakpoints.desktop:
      return [...images["tablet"], ...blobs["tablet"]];
    case width > breakpoints.desktop:
      return [...images["desktop"], ...blobs["desktop"]];
    default:
      return false;
  }
};
