import * as actions from 'mulberry/v2/store/actionTypes/calculator';

let initialState = {
  isLoading: true,
  calculatedProfit: null,
};

export default function calculator(state = initialState, action) {
  switch (action.type) {
    case actions.CALCULATE_PROFIT_SUBMIT:
      return {
        ...state,
        isLoading: true,
      };
    case actions.CALCULATE_PROFIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        calculatedProfit: action.data.profit.data.warranty_revenue
      };
    case actions.CALCULATE_PROFIT_ERROR:
      return {
        ...state,
        isLoading: false,
        calculatedProfit: null,
      };
    case actions.SEND_CONTACT_LEAD_SUBMIT:
      return {
        ...state,
        isLoading: true,
      };
    case actions.SEND_CONTACT_LEAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actions.SEND_CONTACT_LEAD_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
