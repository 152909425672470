import * as actions from './actionTypes';

let initialState = {
  isResetPasswordRequestLoading: false,
  isAllowed: false,
  isPasswordUpdated: false,
  hasError: false,
};

export default function resetPassword (state = initialState, action) {
  switch (action.type) {
    case actions.PASSWORD_REQUEST_SUBMIT:
      return {
        ...state,
        isResetPasswordRequestLoading: true
      };
    case actions.PASSWORD_REQUEST_RESPONSE:
      return state;
    case actions.PASSWORD_REQUEST_ERROR:
      return {
        ...state,
        isResetPasswordRequestLoading: false
      };
    case actions.VALIDATE_CREDENTIALS_RESPONSE:
      return {
        ...state,
        isAllowed: true,
      };
    case actions.VALIDATE_CREDENTIALS_ERROR:
      return {
        ...state,
        isAllowed: false,
      };
    case actions.PASSWORD_RESET_SUBMIT:
      return {
        ...state,
        isPasswordUpdated: false,
        hasError: false,
      };
    case actions.PASSWORD_RESET_RESPONSE:
      return {
        ...state,
        isPasswordUpdated: true,
        hasError: false,
      };
    case actions.PASSWORD_RESET_ERROR:
      return {
        ...state,
        isPasswordUpdated: false,
        hasError: true,
      };
    default:
      return state;
  }
};
