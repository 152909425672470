import { combineReducers } from "redux";
import register from "./PageRegister/reducer";
import warranty from "./PageWarranty/reducer";
import user from './ResetPasswordRequest/reducer';
import calculator from './v2/store/reducers/calculator';
import { reducer as formReducer } from 'redux-form';

const rootReducer = combineReducers({
	form: formReducer,
	register: register,
	warranty,
  user,
  calculator,
});

export default rootReducer;
