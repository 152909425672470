import React from 'react';
import { ThemeProvider } from 'styled-components';

// 0 - mobile = only mobile
// mobile - table = only tablet
// tablet - desktop = small desktop
// desktop - ... = large desktop
export const theme = {
  breakpoints: {
    0: 0,
    mobile: 768,
    tablet: 959,
    smallDesktop: 960,
    desktop: 1170,
    maxContent: 1441,
    xxs: 0,
    xs: 350,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
};

const Breakpoints = ({ children }) => (
  <ThemeProvider theme={theme}>
    { children }
  </ThemeProvider>
);

export default Breakpoints;
