import React, { Component, useEffect } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import { withLDProvider, useFlags } from 'launchdarkly-react-client-sdk'

import { asyncComponent } from 'mulberry/AsyncComponent'
import { LayoutAppTemplate, LayoutContact, LayoutEmpty } from './Layouts'
import {
  getParameterByName,
  nonceSubmit,
  configureIntercomScript,
  configureZendeskScript,
} from 'mulberry/v2/utils'
import config from './config'

const CalculatorPage = React.lazy(async () =>
  import(
    'mulberry/v2/pages/CalculatorPage' /* webpackChunkName:"CalculatorPage" */
  )
)
const PageMultiLogin = React.lazy(async () =>
  import('./PageMultiLogin' /* webpackChunkName:"PageMultiLogin" */)
)
const PageRegister = React.lazy(async () =>
  import('./PageRegister' /* webpackChunkName:"PageRegister" */)
)
const PageRegisterD2C = React.lazy(async () =>
  import('./PageRegister/PageRegisterD2C' /* webpackChunkName:"PageRegisterD2C" */)
)
const Fallback = React.lazy(async () =>
  import('./PageWarranty/index-fallback' /* webpackChunkName:"indexFallback" */)
)
const PostPurchasePage = React.lazy(async () =>
  import('./Pages/PostPurchase/PostPurchase' /* webpackChunkName:"PostPurchase" */)
)
const SquareConfirmation = React.lazy(async () => 
  import('./Pages/SquareConfirmation/Confirmation' /* webpackChunkName:"SquareConfirmation" */)
)
const SquareError = React.lazy(async () => 
  import('./Pages/SquareError/Error' /* webpackChunkName:"SquareError" */)
)
const BigCommerceInstallSuccess = React.lazy(async () => 
  import('./Pages/BigCommerceSuccess/AppInstallSuccess' /* webpackChunkName:"BCInstallSuccess" */)
)
const BigCommerceInstallFail = React.lazy(async () => 
  import('./Pages/BigCommerceFail/AppInstallFail' /* webpackChunkName:"BCInstallFail" */)
)
const MulberryCareConfirmYourPurchase = React.lazy(async () => 
  import('./Pages/MulberryCare/ConfirmYourPurchase' /* webpackChunkName:"ConfirmYourPurchase" */)
)
const PageWarrantySearch = React.lazy(async () =>
  import('./PageWarranty/search' /* webpackChunkName:"PageWarrantySearch" */)
)
const ResetPasswordRequestPage = React.lazy(async () =>
  import(
    './ResetPasswordRequest/ResetPasswordRequestPage' /* webpackChunkName:"ResetPasswordRequestPage" */
  )
)
const ResetPasswordUpdatePage = React.lazy(async () =>
  import(
    './ResetPasswordRequest/ResetPasswordUpdatePage' /* webpackChunkName:"ResetPasswordUpdatePage" */
  )
)

const PageActivationLinkResend = React.lazy(async () =>
    import('./PageActivationLinkResend') /* webpackChunkName:"PageActivationLinkResend" */
)

const Homepage = () => {
  window.location.href = 'https://www.getmulberry.com'
  return null
}

const AppRoute = () => {
  const flags = useFlags()

  useEffect(() => {
    const disAllowedRoutes = ['/carts', '/login', '/register']
    const allowed = (route) =>
      window.location.href.indexOf(route) === -1 && true

    // Don't show chats on carts, login, register, or search pages.
    if (Object.keys(flags).length !== 0 && disAllowedRoutes.every(allowed)) {
      // TODO: remove these conditions on full transition to Zendesk.
      if (flags.toggleZendesk) {
        configureZendeskScript()
      } else {
        configureIntercomScript()
      }
    }
  }, [flags])

  if (window.location.search) {
    const nonce_param = getParameterByName('nonce')

    if (nonce_param) {
      nonceSubmit(nonce_param, config.dashboard)
      return null
    }
  }

  window.scrollTo(0, 0)

  return <Outlet />
}

class App extends Component {
  render() {
    return (
      <Routes>
        <Route element={<AppRoute />}>
          <Route element={<LayoutAppTemplate />}>
            <Route exact path="/calculator" element={<CalculatorPage />} />
            <Route exact path="/reset-password-request" element={<ResetPasswordRequestPage />} />
            <Route exact path="/reset-password-confirm/:email/:token" element={<ResetPasswordUpdatePage />} />
          </Route>
          <Route element={<LayoutContact />}>
            <Route exact path="/register/:token" element={<PageRegister />} />
            <Route exact path="/register-d2c/:token" element={<PageRegisterD2C />} />
          </Route>
          <Route element={<LayoutEmpty />}>
            <Route exact path="/login" element={<PageMultiLogin />} />
            <Route exact path="/login/:role" element={<PageMultiLogin />} />
            <Route exact path="/resend-activation-link" element={<PageActivationLinkResend />} />
            <Route path="/carts/:retailerId/checkout/anonymous" element={<Fallback />} />
            <Route exact path="/carts/:retailerId/checkout/:orderId?" element={<PostPurchasePage />} />
            <Route exact path="/square-install/:merchantUuid/confirmation" element={<SquareConfirmation />} />
            <Route exact path="/square-install/error" element={<SquareError />} />
            <Route exact path="/bigcommerce-install/success" element={<BigCommerceInstallSuccess />} />
            <Route exact path="/bigcommerce-install/fail" element={<BigCommerceInstallFail />} />
            <Route exact path="/mulberrycare-confirm" element={<MulberryCareConfirmYourPurchase />} />
            <Route exact path="/protect-your-purchase/:retailerId" element={<PageWarrantySearch />} />
          </Route>
        </Route>
        <Route path="*" element={<Homepage />} />
      </Routes>
    )
  }
}

export default withLDProvider({
  clientSideID: config.launchDarklyClientID,
  user: {
    key: 'anon',
  },
})(App)
