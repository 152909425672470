// password reset request
export const PASSWORD_REQUEST_SUBMIT = 'PASSWORD_REQUEST_SUBMIT';
export const PASSWORD_REQUEST_RESPONSE = 'PASSWORD_REQUEST_RESPONSE';
export const PASSWORD_REQUEST_ERROR = 'PASSWORD_REQUEST_ERROR';

// user credential check
export const VALIDATE_CREDENTIALS_SUBMIT = 'VALIDATE_CREDENTIALS_SUBMIT';
export const VALIDATE_CREDENTIALS_RESPONSE = 'VALIDATE_CREDENTIALS_RESPONSE';
export const VALIDATE_CREDENTIALS_ERROR = 'VALIDATE_CREDENTIALS_ERROR';

// password reset
export const PASSWORD_RESET_SUBMIT = 'PASSWORD_RESET_SUBMIT';
export const PASSWORD_RESET_RESPONSE = 'PASSWORD_RESET_RESPONSE';
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR';
