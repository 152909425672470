import { Link } from 'react-router-dom';
import styled from "styled-components";

export const breakpoints = {
  tablet: 501,
  desktop: 1240,
}

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px;
  max-width: 1200px;
  margin: 0 auto;
`

export const NavLogoMobile = styled.li`
  @media (min-width: ${breakpoints.desktop}px) {
    display: none;
  }

  ul[class*='styles__is-active'] & {
    margin-bottom: 36px;
    padding: 6px 0 0;
  }

  img {
    display: block;
    height: 33px;
    width: 149px;
  }
`

export const NavButton = styled(Link)`
  background: white;
  border: 1px solid;
  border-radius: 4px;
  color: #ff8400;
  display: inline-block;
  font: normal 16px/20px 'MuseoSans500';
  letter-spacing: normal;
  padding: 15px 23px;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    text-align: center;
    width: calc(50% - 8px);
  }
`

export const ButtonLi = styled.li`
  @media (min-width: ${breakpoints.desktop}px) {
    margin-left: 60px;
    order: 9;

    &:nth-child(n) {
      margin-right: 0;
    }
  }

  ${NavButton} {
    color: #ff8400;
    padding: 15px 23px;

    &:hover {
      border-width: 1px;
    }
  }
`
