import {
  CART_TOTAL_RESPONSE,
  CUSTOMER_ERROR,
  CUSTOMER_RESPONSE,
  CUSTOMER_UPDATE,
  SET_SELECTED_WARRANTIES,
  UPDATE_MERGED_WARRANTIES,
} from "./actions";

let initialState = {
  customer: null,
  customerError: false,
  selectedWarranties: [],
  isVagrantOrderActive: true,
  cartTotal: {
    sub_total: 0,
    tax_amount: 0,
    total: 0,
  },
  mergedWarranties: {},
};

export default function register(state = initialState, action) {
  switch (action.type) {
    case CUSTOMER_RESPONSE:
      if (Array.isArray(action.payload.warranties[0])) {
        action.payload.warranties.map((warranty) => warranty.reverse());
      }
      return {
        ...state,
        customer: action.payload.customer.billing_address,
        retailer: action.payload.retailer,
        warranties: action.payload.warranties,
        styles: action.payload.styles,
        isVagrantOrderActive: action.payload.is_active,
        isVagrantOrderExpired: action.payload.expired,
      };
    case CUSTOMER_UPDATE:
      return {
        ...state,
        customer: action.payload,
      };
    case CUSTOMER_ERROR:
      return {
        ...state,
        customerError: true,
      };
    case CART_TOTAL_RESPONSE:
      return {
        ...state,
        cartTotal: action.payload,
      };
    case SET_SELECTED_WARRANTIES:
      return {
        ...state,
        selectedWarranties: action.payload,
      };
    case UPDATE_MERGED_WARRANTIES:
      return {
        ...state,
        mergedWarranties: action.payload,
      };
    default:
      return state;
  }
}
