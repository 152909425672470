import React from 'react';
import { Outlet } from 'react-router-dom'
import AppTemplate from 'mulberry/v2/templates/AppTemplate'
import BreakpointsProvider from 'mulberry/ThemeProvider/ThemeProvider'

export const LayoutAppTemplate = () => (
  <AppTemplate>
    <BreakpointsProvider>
      <Outlet />
    </BreakpointsProvider>
  </AppTemplate>
)
