import React from 'react'
import Logo from "../Logo";
import mulberryLogo from "mulberry/assets/images/mulberry-logo-purple.svg";
import { ContactContainer } from './Nav.style';

const ContactNav = () => (
  <ContactContainer>
    <Logo src={mulberryLogo} target="_blank" />
  </ContactContainer>
)

export default ContactNav
