export function getStyles(retailer) {
  const colors = retailer;
  return {
    button: {
      background: colors.color_primary
    },
    heading: {
      color: colors.color_secondary
    },
    coverage: {
      background: colors.color_tertiary
    },
    link: {
      color: colors.color_primary
    }
  };
}
