import React from 'react';
import { Outlet } from 'react-router-dom'
import ContactNav from 'mulberry/Nav/ContactNav';

export const LayoutContact = () => (
  <>
    <ContactNav />
    <Outlet />
  </>
)
