import axios from 'axios';
import config from '../config';

export const REGISTER_SUBMIT = 'REGISTER_SUBMIT';
export const REGISTER_RESPONSE = 'REGISTER_RESPONSE';
export const TOKEN_SUBMIT = 'TOKEN_SUBMIT';
export const TOKEN_RESPONSE = 'TOKEN_RESPONSE';

export function registerResponse(json) {
    return { type: REGISTER_RESPONSE, stats: json.data };
}

export function registerSubmit(payload = {}) {
	return dispatch => {
		dispatch({ type: REGISTER_SUBMIT })

		return axios.post(`${config.api}/api/auth/register`, payload, {
			headers: {
				'Authorization': `Bearer ${payload.token}`
			}
		})
		.then(json => dispatch(registerResponse(json)))
		.catch((err) => err)
    }
}

export function tokenResponse(json) {
    return { type: TOKEN_RESPONSE, token: json.data };
}

export function tokenSubmit(token) {
	const payload = {token}
	return dispatch => {
		return axios.post(`${config.api}/api/auth/analyze_token`, payload)
			.then(json => dispatch(tokenResponse(json)))
    }
}