import React, { Component } from 'react'
import { useLocation } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import Logo from '../Logo'
import styles from './styles.module.css'

import { theme as BREAKPOINTS } from 'mulberry/ThemeProvider/ThemeProvider'
import mulberryLogo from 'mulberry/assets/images/mulberry-logo-blue.png'
import mulberryLogoWhite from 'mulberry/assets/images/mulberry-logo-horizontal-white.svg'

const getThemedLogo = (path) => {
  switch (true) {
    case ['/calculator', '/partner-with-us'].includes(path) &&
      window.innerWidth > BREAKPOINTS.breakpoints.maxContent:
      return {
        logo: mulberryLogoWhite,
      }
    case [
      '/retailers/boost-customer-loyalty',
      '/retailers/faster-and-smarter',
      '/partner-with-us',
      '/contact',
      '/calculator',
    ].includes(path) && window.innerWidth < BREAKPOINTS.breakpoints.maxContent:
      return {
        logo: mulberryLogoWhite,
      }
    default:
      return {
        logo: mulberryLogo,
      }
  }
}

class Nav extends Component {
  state = {
    isMobile: window.innerWidth < 1240,
    isOpen: false,
  }
  classes = []

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }
  handleResize = () => {
    this.setState({
      isMobile: window.innerWidth < 1240,
    })
  }
  onExtendedNavClick = () => {
    this.setState({ isOpen: false })
  }
  toggle = () => {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }))
    this.burgerClass = ''
  }
  componentDidUpdate() {
    if (this.state.isOpen && !this.state.isMobile) {
      this.setState({ isOpen: false })
    }
  }

  render() {
    const { location } = this.props
    const { isOpen } = this.state
    if (this.state !== null && isOpen) {
      this.burgerClass = styles['is-active']
    } else {
      this.burgerClass = ''
    }

    this.classes.push(styles.burgerWrap)
    return (
      <div className={styles.container}>
        <Logo src={getThemedLogo(location.pathname).logo} />
      </div>
    )
  }
}

const NavWrapper = () => {
  const location = useLocation()
  const { toggleMultipleLogins } = useFlags()

  return <Nav {...{ location, toggleMultipleLogins }} />
}

export default NavWrapper
