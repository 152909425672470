import axios from "axios";
import config from "../config";
import { getStyles } from "./util";

axios.defaults.withCredentials = true;

export const CUSTOMER_FETCH = "CUSTOMER_FETCH";
export const CUSTOMER_RESPONSE = "CUSTOMER_RESPONSE";
export const CUSTOMER_UPDATE = "CUSTOMER_UPDATE";
export const CUSTOMER_ERROR = "CUSTOMER_ERROR";
export const CART_TOTAL_FETCH = "CART_TOTAL_FETCH";
export const CART_TOTAL_RESPONSE = "CART_TOTAL_RESPONSE";
export const SET_SELECTED_WARRANTIES = "SET_SELECTED_WARRANTIES";
export const CHECKOUT = "CHECKOUT";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const UPDATE_MERGED_WARRANTIES = "UPDATE_MERGED_WARRANTIES";

export function customerResponse(json) {
  const payload = {
    ...json.data,
    styles: getStyles(json.data.retailer),
  };
  return { type: CUSTOMER_RESPONSE, payload };
}

export function customerUpdate(details) {
  const newDetails = {
    ...details,
    country: "US",
  };
  return { type: CUSTOMER_UPDATE, payload: newDetails };
}

export function customerError() {
  return { type: CUSTOMER_ERROR };
}

export function chargeSubmit(token, charge, email, checkoutPayload, paymentProcessor) {
  return () => {
    return axios.post(`${config.api}/api/charge`, {
      token: token,
      charge,
      email,
      checkout_payload: checkoutPayload,
      payment_processor: paymentProcessor
    });
  };
}

export async function chargeSubmitNew(token, charge, email, checkoutPayload, paymentProcessor) {
  return () => {
    return axios.post(`${config.api}/api/post-purchase-charge`, {
      token: token,
      charge,
      email,
      checkout_payload: checkoutPayload,
      payment_processor: paymentProcessor
    });
  };
}

export function customerFetch(retailerId, orderId) {
  return (dispatch) => {
    return axios
      .get(`${config.api}/api/purchase`, {
        params: {
          order_id: orderId,
          retailer_uuid: retailerId,
        },
      })
      .then((json) => {
        dispatch(customerResponse(json));
        dispatch(mergeWarranties(json.data));
        return json;
      });
  };
}

export function cartTotalFetch(payload, orderId) {
  return (dispatch) => {
    dispatch(setSelectedWarranties(payload.warranties))
    return axios
      .post(`${config.api}/api/total${orderId ? '/' + orderId : ''}`, payload)
      .then((json) => dispatch(cartTotalResponse(json)));
  };
}

export function cartTotalResponse(json) {
  return { type: CART_TOTAL_RESPONSE, payload: json.data };
}

export function setSelectedWarranties(warranties) {
  return { type: SET_SELECTED_WARRANTIES, payload: warranties };
}

export function checkoutAnonymous(payload) {
  return (dispatch) => {
    return axios
      .post(`${config.api}/api/anonymous_checkout`, payload)
      .then((json) => {
        dispatch(checkoutSuccess(json));
        return json;
      });
  };
}

export function checkout(payload) {
  return (dispatch) => {
    return axios
      .post(`${config.api}/api/vagrant_checkout`, payload)
      .then((json) => {
        dispatch(checkoutSuccess(json));
        return json;
      });
  };
}

export function checkoutSuccess(json) {
  return { type: CHECKOUT_SUCCESS, payload: json.data };
}

export function decreaseQuantity(state, warranty_hash) {
  const newState = { ...state };
  newState[warranty_hash].selected -= 1;
  return { type: UPDATE_MERGED_WARRANTIES, payload: newState };
}

export function increaseQuantity(state, warranty_hash) {
  const newState = { ...state };
  newState[warranty_hash].selected += 1;
  return { type: UPDATE_MERGED_WARRANTIES, payload: newState };
}

const mergeWarranties = (response) => {
  const warranties = response.warranties;
  const uniqueWarranties = {};
  const itemsNumber = {};

  warranties.map((el) => {
    /* create unique objects based on warranty_hash */
    uniqueWarranties[el.warranty_hash] = el;

    /* count number of objects with specific warranty_hash */
    itemsNumber[el.warranty_hash]
      ? (itemsNumber[el.warranty_hash].quantity += 1)
      : (itemsNumber[el.warranty_hash] = { quantity: 1 });

    /* merge two objects and setup default selected quantity to available */
    return Object.keys(uniqueWarranties).map(function (key, index) {
      uniqueWarranties[key].quantity = 0;
      uniqueWarranties[key].selected = 0;

      return uniqueWarranties;
    });
  });

  return { type: UPDATE_MERGED_WARRANTIES, payload: uniqueWarranties };
};
