import React from "react";
import { createRoot } from "react-dom/client";
import "./index.module.css";
import App from "./App.jsx";
import rootReducer from "./root-reducer";

import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux"
import { BrowserRouter } from "react-router-dom";
import { MixpanelProvider } from "react-mixpanel";
import thunk from "redux-thunk";
import mixpanel from "mixpanel-browser";
import config from "./config";
import { getResponsiveAssets } from "./image-prefetch";
import prefetchImages from "prefetch-image";

mixpanel.init(config.mixpanelToken);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <BrowserRouter>
      <MixpanelProvider mixpanel={mixpanel}>
        <App />
      </MixpanelProvider>
    </BrowserRouter>
  </Provider>
);

prefetchImages(getResponsiveAssets());
