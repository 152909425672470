import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Navbar from 'mulberry/Nav';
import ThemeProvider from 'mulberry/ThemeProvider/ThemeProvider';


const AppContainer = styled.div`
  padding: 10px 15px 0;
  ${breakpoint('desktop')`
    padding: 20px 90px 0;
  `}
  ${breakpoint(0, 'mobile')`
    padding: 10px 15px 0;
  `}
`;
const Main = styled.main`
  margin-top: 0;
  ${breakpoint(0, 'mobile')`
    margin-top: 0;
  `}
  ${breakpoint('desktop')`
    margin-top: 20px;
  `}
`;
const ContentLimitation = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`;

const AppTemplate = ({ children }) => (
  <ThemeProvider>
    <ContentLimitation>
      <AppContainer>
        <Navbar />
        <Main>
          { children }
        </Main>
      </AppContainer>
    </ContentLimitation>
  </ThemeProvider>
);

AppTemplate.propTypes = {
  children: PropTypes.object,
};
AppTemplate.defaultProps = {
  children: [],
};

export default AppTemplate;
